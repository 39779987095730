@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sono:wght@500&display=swap');

body {
  /* margin: 0; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(210, 209, 209);
  width: max-content;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.font-header-print {
  font-family: 'Lobster', cursive;
}

.font-hearder-print-2 {
  font-family: 'Luckiest Guy', cursive;
  letter-spacing: 0.3rem;
}

.font-stroke-pembelian {
  font-family: 'Sono', sans-serif;
}

/* 
@media print {
  @page{
    size: 120mm 297mm;
  }
  .modal-stroke {
    display: block !important;
    position: static !important;
    visibility: visible !important;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    transform: none;
    box-shadow: none;
  }

  body {
    width: 210mm;
    height: 297mm;
    margin: auto;
    color: #000;
    background-color: #fff;
  }
} */
