@media print {
  /* @page{
      size: 80mm 297mm;
      page-break-before: always;
      margin: 0;
      padding: 0;
    } */
  .modal-stroke {
    display: block !important;
    position: static !important;
    visibility: visible !important;
    page-break-before: always;
    size: 40mm 297mm;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    transform: none;
    box-shadow: none;
    font-size: large;
  }

  /* body {
      width: 300mm;
      height: 297mm;
      margin: 0;
      padding: 0;
      color: #000;
      background-color: #fff;
    } */
}
